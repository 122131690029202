@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom scrollbar styling */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #22C55E;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #16A34A;
}

/* Table styles */
.table-container {
  scrollbar-width: thin;
  scrollbar-color: #22C55E #f1f1f1;
}